import React, { useState } from 'react';

const MusicRecommendationApp = () => {
const [searchTerm, setSearchTerm] = useState('');
const [selectedItems, setSelectedItems] = useState([]);
const [suggestions, setSuggestions] = useState([]);
const [recommendations, setRecommendations] = useState([]);
const [isLoading, setIsLoading] = useState(false);


const getSuggestions = async (term) => {
  if (term.length > 1) {
    try {
      const response = await fetch(`/api/suggestions?query=${encodeURIComponent(term)}`);
      const data = await response.json();
      setSuggestions(data.suggestions);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setSuggestions([]);
    }
  } else {
    setSuggestions([]);
  }
};

const formatSuggestion = (item) => {
  if (item.type === 'track') {
    // Remove any "- Remaster" or similar suffixes from the track name
    const cleanTrackName = item.name.split(' - ')[0];
    return `${cleanTrackName} - ${item.artist}`;
  }
  else if (item.type == 'artist') {
    return `${item.name} (Artist)`;
  }
  else {
    return `${item.name}`;
  }
};

const handleSearchChange = (e) => {
  const term = e.target.value;
  setSearchTerm(term);
  if (term.length > 1) {
    getSuggestions(term);
  } else {
    setSuggestions([]);
  }
};

  const handleSuggestionClick = (item) => {
    if (!selectedItems.includes(item)) {
      setSelectedItems([...selectedItems, item]);
    }
    setSearchTerm('');
    setSuggestions([]);
  };

  const handleRemoveItem = (item) => {
    setSelectedItems(selectedItems.filter(i => i !== item));
  };

  const handleGetRecommendations = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('/api/recommendations', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ selectedSongs: selectedItems }),
      });
      const data = await response.json();
      setRecommendations(data.recommendations);
    } catch (error) {
      console.error('Error fetching recommendations:', error);
      setRecommendations([]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-xl">
      <h1 className="text-2xl font-bold mb-4">Music Recommendation App</h1>
      
      <div className="relative">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search for songs or artists..."
          className="w-full p-2 border rounded"
        />
        {suggestions.length > 0 && (
          <ul className="absolute z-10 w-full bg-white border rounded mt-1">
            {suggestions.map((item) => (
              <li
                key={item.id}
                onClick={() => handleSuggestionClick(item)}
                className="p-2 hover:bg-gray-100 cursor-pointer"
              >
                {formatSuggestion(item)}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="mt-4 flex flex-wrap gap-2">
        {selectedItems.map((item) => (
          <div key={item.id} className="bg-blue-100 text-blue-800 px-2 py-1 rounded-full flex items-center">
            <span>{formatSuggestion(item)}</span>
            <button
              onClick={() => handleRemoveItem(item)}
              className="ml-1 text-sm font-bold cursor-pointer"
            >
              ×
            </button>
          </div>
        ))}
      </div>

      <button
        onClick={handleGetRecommendations}
        className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        disabled={isLoading}
      >
        {isLoading ? 'Loading...' : 'Get Recommendations'}
      </button>

      {recommendations.length > 0 && (
        <div className="mt-4">
          <h2 className="text-xl font-semibold mb-2">Recommendations:</h2>
          <ul className="list-disc pl-5">
            {recommendations.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MusicRecommendationApp;